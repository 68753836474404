import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AvcMapsDisplayService, Content } from '@garmin-avcloud/avc-maps-display';
import { AcdcAircraftTrackResponse } from '@shared/models/acdc-realtime-traffic/acdc-aircraft-track-response.model';
import { Observable, catchError, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AcdcRealtimeTrafficService {
  chanTrafficUrl = '';

  private readonly AvcMapsService = inject(AvcMapsDisplayService);
  private readonly httpClient = inject(HttpClient);

  getTrafficTrackByRegistration(registration: string): Observable<AcdcAircraftTrackResponse | null> {
    const capsContent: Content[]  = this.AvcMapsService.info.overlay.internetTraffic.content();
    if (capsContent.length > 0) {
      this.chanTrafficUrl = capsContent[0].urlBase;

      return this.httpClient
        .get<AcdcAircraftTrackResponse>(`${this.chanTrafficUrl}/track/last/${registration}`, {
          withCredentials: true
        })
        .pipe(catchError(() => of(null)));
    } else {
      return of(null);
    }
  }
}
