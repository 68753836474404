import { Injectable, Signal, computed } from '@angular/core';
import { CustomerSettingsProvider } from '@garmin-avcloud/avcloud-web-utils';
import { Unit } from '@shared/pipes/unit-converter/unit-converter.pipe';

@Injectable({
  providedIn: 'root'
})
export class UnitPreferencesService {
  /**
     The observable representing the current unit preference for
     coordinate format. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.coordinateFormat.pipe(takeUntilDestroyed()).subscribe((str) => this.format = str);
  */
  readonly coordinateFormat: Signal<string>;

  /**
     The observable representing the current unit preference for
     runway length. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.runwayLength.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly runwayLength: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     distance. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.distance.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly distance: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     arm/CG. The implementation will make a best-effort to provide a
     value upon subscription.

     @example
     unitPreferences.armCG.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly armCG: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     visibility. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.visibility.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly visibility: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     altimeter pressure. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.altimeter.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly altimeter: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     wind speed. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.windSpeed.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly windSpeed: Signal<Unit>;

  /**
     The observable representing the current unit preference for
     temperature. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.temperature.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly temperature: Signal<Unit>;

  /**
     Get the observable representing the current unit preference for
     temperature aloft. The implementation will make a best-effort to
     provide a value upon subscription.

     @example
     unitPreferences.temperatureAloft.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u);
  */
  readonly temperatureAloft: Signal<Unit>;

  /**
   * Get the observable representing the current unit preference for fluid volume.
   * The implementation will make a best-effort to provide a value upon subscription.
   *
   * @example
   * unitPreferences.fluidVolume.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u)
   */
  readonly fluidVolume: Signal<Unit>;

  /**
   * Get the observable representing the current unit preference for velocity.
   * The implementation will make a best-effort to provide a value upon subscription.
   *
   * @example
   * unitPreferences.velocity.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u)
   */
  readonly velocity: Signal<Unit>;

  /**
   * Get the observable representing the current unit preference for UTC time or Local time.
   * The implementation will make a best-effort to provide a value upon subscription.
   *
   * @example
   * unitPreferences.utcLocalTime.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u)
   */
  readonly utcLocalTime: Signal<Unit>;

  /**
   * Get the observable representing the current unit preference for time formatting.
   * The implementation will make a best-effort to provide a value upon subscription.
   *
   * @example
   * unitPreferences.timeFormat.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u)
   */
  readonly timeFormat: Signal<Unit>;

  /**
   * Get the Observable representing the current unit preference for weight.
   * The implementation will make a best-effort to provide a value upon subscription.
   *
   * @example
   * unitPreference.weight.pipe(takeUntilDestroyed()).subscribe((u) => this.unit = u)
   */
  readonly weight: Signal<Unit>;

  constructor(private readonly settingsProvider: CustomerSettingsProvider) {
    // COORDINATES
    this.coordinateFormat = computed(() => this.settingsProvider.getCustomerSetting('Latitude/Longitude')()
      .replace(/[\u02DA\u00BA]/g, '\u00B0')
      .replace(/s(?:"?)$/, 's"'));

    // LENGTHS
    this.runwayLength = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Runway Length')();
      switch (settingVal) {
        case 'Meters':
          return Unit.METER;
        case 'Feet':
          return Unit.FEET;
        default:
          throw new Error(`Unsupported runway length unit: ${settingVal}`);
      }
    });

    this.distance = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Distance')();
      switch (settingVal) {
        case 'Miles':
          return Unit.STATUTE_MILE;
        case 'Kilometers':
          return Unit.KILOMETER;
        case 'Nautical Miles':
          return Unit.NAUTICAL_MILE;
        default:
          throw new Error(`Unsupported distance unit: ${settingVal}`);
      }
    });

    this.armCG = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Arm CG')();
      switch (settingVal) {
        case 'Inches':
          return Unit.INCHES;
        case 'Millimeters':
          return Unit.MILLIMETER;
        default:
          throw new Error(`Unsupported arm/CG unit: ${settingVal}`);
      }
    });

    this.visibility = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Visibility')();
      switch (settingVal) {
        case 'Miles':
          return Unit.STATUTE_MILE;
        case 'Meters':
          return Unit.METER;
        default:
          throw new Error(`Unsupported visibility unit: ${settingVal}`);
      }
    });

    // PRESSURE
    this.altimeter = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Altimeter')();
      switch (settingVal) {
        case 'Hectopascals':
          return Unit.HECTOPASCAL;
        case 'Inches of Mercury':
          return Unit.INCHES_OF_MERCURY;
        case 'Kilopascals':
          return Unit.KILOPASCAL;
        case 'Millibars':
          return Unit.MILLIBAR;
        case 'Pound-force Per Square Inch':
          return Unit.POUNDS_PER_SQUARE_INCH;
        default:
          throw new Error(`Unsupported altimeter unit: ${settingVal}`);
      }
    });

    // SPEEDS
    this.windSpeed = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Wind Speed')();
      switch (settingVal) {
        case 'Kilometers/Hour':
          return Unit.KILOMETERS_PER_HOUR;
        case 'Miles/Hour':
          return Unit.MILES_PER_HOUR;
        case 'Meters/Second':
          return Unit.METERS_PER_SECOND;
        case 'Knots':
          return Unit.KNOTS;
        default:
          throw new Error(`Unsupported wind speed unit: ${settingVal}`);
      }
    });

    this.velocity = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Velocity')();
      switch (settingVal) {
        case 'Kilometers/Hour':
          return Unit.KILOMETERS_PER_HOUR;
        case 'Miles/Hour':
          return Unit.MILES_PER_HOUR;
        case 'Meters/Second':
          return Unit.METERS_PER_SECOND;
        case 'Knots':
          return Unit.KNOTS;
        default:
          throw new Error(`Unsupported wind speed unit: ${settingVal}`);
      }
    });

    // TEMPERATURES
    this.temperature = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Default Temperature')();
      switch (settingVal) {
        case 'Degrees Celsius':
          return Unit.DEGREE_CELSIUS;
        case 'Degrees Fahrenheit':
          return Unit.DEGREE_FAHRENHEIT;
        default:
          throw new Error(`Unsupported default temperature unit: ${settingVal}`);
      }
    });

    this.temperatureAloft = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Temperature Aloft')();
      switch (settingVal) {
        case 'Degrees Celsius':
          return Unit.DEGREE_CELSIUS;
        case 'Degrees Fahrenheit':
          return Unit.DEGREE_FAHRENHEIT;
        default:
          throw new Error(`Unsupported temperature aloft unit: ${settingVal}`);
      }
    });

    //VOLUME
    this.fluidVolume = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Fluid Volume')();
      switch (settingVal) {
        case 'Gallons':
          return Unit.GALLON;
        case 'Liters':
          return Unit.LITER;
        default:
          throw new Error(`Unsupported Fluid Volume unit: ${settingVal}`);
      }
    });

    //TIME
    this.utcLocalTime = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('UTC/Local')();
      switch (settingVal) {
        case 'Local':
          return Unit.LOCAL;
        case 'UTC':
          return Unit.UTC;
        default:
          throw new Error(`Unsupported time unit: ${settingVal}`);
      }
    });

    this.timeFormat = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Format')();
      switch (settingVal) {
        case '12-Hour':
          return Unit.TWELVE_HOUR;
        case '24-Hour':
          return Unit.TWENTY_FOUR_HOUR;
        default:
          throw new Error(`Unsupported time format unit: ${settingVal}`);
      }
    });

    //WEIGHT
    this.weight = computed(() => {
      const settingVal = this.settingsProvider.getCustomerSetting('Weight')();
      switch (settingVal) {
        case 'Pounds':
          return Unit.POUNDS;
        case 'Kilograms':
          return Unit.KILOGRAMS;
        default:
          throw new Error(`Unsupported weight format unit: ${settingVal}`);
      }
    });
  }

  getPreferredTimeZone(localTimezone?: string): string {
    return this.utcLocalTime() === Unit.UTC ? 'UTC' : localTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
