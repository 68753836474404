import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MapIcon, aircraftMappingTable } from '@garmin-avcloud/avc-maps-display';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { Icon, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { AcdcAircraftTrackResponse } from '@shared/models/acdc-realtime-traffic/acdc-aircraft-track-response.model';
import { Unit, UnitAbbreviations, UnitConverterPipe } from '@shared/pipes/unit-converter/unit-converter.pipe';
import { MapIcons } from 'projects/avcloud-pilotweb/src/app/features/map/constants/map-icons';
import { UnitPreferencesService } from '../../../services/unit-preferences/unit-preferences.service';

@Component({
  selector: 'pilot-aircraft-search-card',
  templateUrl: './aircraft-search-card.component.html',
  styleUrls: ['./aircraft-search-card.component.scss'],
  standalone: true,
  imports: [CommonModule, FlyCardModule, FlyIconModule]
})

export class AircraftSearchCardComponent implements OnInit {
  @Input() aircraft: AcdcAircraftTrackResponse;
  @Output() readonly selected = new EventEmitter<AcdcAircraftTrackResponse>();

  readonly MapIcons = MapIcons;
  readonly aircraftMappingTable = aircraftMappingTable;
  icon: Icon;
  isSelected = false;
  altitude: string = '--';
  speed: string = '--';
  speedUnit: string = 'KT';

  private readonly unitConverterPipe = new UnitConverterPipe();
  protected readonly unitPreferences = inject(UnitPreferencesService);

  ngOnInit(): void {
    this.icon = this.MapIcons.INTERNET_TRAFFIC[aircraftMappingTable[this.aircraft.aircraft_type] as keyof typeof this.MapIcons.INTERNET_TRAFFIC ?? MapIcon.InternetTraffic_Generic];
    const aircraftLastPosition = this.aircraft.position.at(-1);
    if (aircraftLastPosition != null) {
      this.altitude = this.aircraft.position.at(-1)!.altitude.toString();
      this.speed = this.unitPreferences.velocity() !== Unit.KNOTS ? this.unitConverterPipe.transform(this.aircraft.position.at(-1)!.speed, Unit.KNOTS, this.unitPreferences.velocity()).toString() : this.aircraft.position.at(-1)!.speed.toString();
    } else {
      this.altitude = '--';
      this.speed = '--';
    }
    this.speedUnit = UnitAbbreviations[this.unitPreferences.velocity()];
  }

  onSelect(): void {
    this.selected.emit(this.aircraft);
    this.isSelected = true;
  }

  unselect(): void {
    this.isSelected = false;
  }

}
