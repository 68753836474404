<fly-card class="search-card"
  [selected]="isSelected"
  (selectedChange)="onSelect()"
  [padding]="'reduced'">
  <div class="wrapper">
    <div class="icon">
      <fly-icon [icon]="icon"></fly-icon>
    </div>
    <div class="data">
      <span>{{ aircraft.id }}</span>
      <span class="aircraft-type">{{ aircraft.aircraft_type }}</span>
      <div class="details">
        @if(aircraft.origin || aircraft.destination) {
          <div class="column">
            {{ aircraft.origin && aircraft.destination ?
              aircraft.origin + ' - ' + aircraft.destination :
              aircraft.origin ?? aircraft.destination}}
          </div>
        }
        @if(altitude !== '--') {
          <div class="column">
            {{altitude|number:'0.0-0'}}FT
          </div>
        }
        @if(speed !== '--') {
          <div class="column">
            {{speed|number:'0.0-0'}}{{speedUnit}}
          </div>
        }
      </div>
    </div>
  </div>
</fly-card>
